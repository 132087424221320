import "./App.css";
import { useRef, useEffect } from "react";

function App() {
  // upwind's code
  // invoked as part of building the DOM
  function component() {
    const element = document.createElement("div");
    element.innerHTML = "<h1>Bandwidth Measurement</h1>";
    return element;
  }

  let report = component();
  document.body.appendChild(report);

  function addLine(text) {
    console.log(text);
    const p = document.createElement("p");
    report.append(text, p);
  }

  const clientLocation = useRef(null);

  async function getLoc() {
    let cityCountry = "Unknown";
    try {
      const resp = await fetch("https://api.db-ip.com/v2/free/self");

      if (!resp.ok) {
        throw new Error(`Response status: ${resp.status}`);
      }

      const locJSON = await resp.json();
      //cityCountry = locJSON.city + ", " + locJSON.countryName;
      clientLocation.current = locJSON;
    } catch (error) {
      console.error(error.message);
    }
  }

  async function doTest() {
    const configOptions = {
      timeout: "8",
      numRangeWorkers: "1",
      reqAvgBW_Kbps: "5000",
    };

    console.log("timeout: " + configOptions.timeout);
    console.log("numWk: " + configOptions.numRangeWorkers);
    console.log("reqAvgBW_Kbit/s: " + configOptions.reqAvgBW_Kbps);

    addLine("starting...");

    let bytes = [];
    let responseTime = [];
    let elapsed = [];
    const individualResults = [];
    let totalBytesFetched = 0;

    const baseUrl = "https://pworker1.plshang.com/";
    let testStart = Date.now();

    for (let i = 1; i <= 6; i++) {
      let url = baseUrl + "file" + i + ".data";
      let startTime = Date.now();
      try {
        let response = await fetch(url, {
          signal: AbortSignal.timeout(configOptions.timeout * 1000),
        });
        responseTime.push(Date.now() - startTime);
        let data = await response.arrayBuffer();
        bytes.push(data.byteLength);
      } catch (e) {
        //TODO: Add throw for file in baseURL not accessible
        if (e.name) console.log(e.name);
        console.log("Error in file " + i);
      }
      elapsed.push(Date.now() - startTime);
    }
    const totalTimeMS = Date.now() - testStart;

    //output to page if manually checking own location
    for (let i = 0; i < 6; i++) {
      individualResults.push({
        test_number: i + 1,
        test_filename: "file" + (i + 1),
        bytes_fetched: bytes[i],
        fetched_duration_ms: responseTime[i],
        duration_last_byte_ms: elapsed[i],
      });

      addLine(
        " file" +
          (i + 1) +
          " " +
          bytes[i] +
          " bytes, fetch " +
          responseTime[i] +
          " ms, data " +
          elapsed[i] +
          " ms"
      );
      totalBytesFetched += bytes[i];
    }
    addLine("Total test time " + totalTimeMS + " ms");
    addLine("Total bytes transferred " + totalBytesFetched);

    // VLC displays kbps; QT displays Mbit/s - both use bits
    // Using kbps to ensure track slowest speeds
    const averageBandwidthKbps = Math.floor(
      (totalBytesFetched * 8) / totalTimeMS
    );

    const bSuccess = averageBandwidthKbps > configOptions.reqAvgBW_Kbps;

    addLine(
      "Average bandwidth " +
        averageBandwidthKbps +
        " Kbits /sec  (" +
        Math.floor((averageBandwidthKbps * 100) / 8000) / 100 +
        " MBytes /sec) "
    );
    addLine(" Pass: " + bSuccess);

    const bandwidthTestsJson = {
      test_start_dt: new Date().toISOString(),
      configOptions: configOptions,
      client_location: clientLocation.current,
      individualResults: individualResults,
      results_summary: {
        total_time_ms: totalTimeMS,
        total_bytes_fetched: totalBytesFetched,
        average_bandwidth_kbps: averageBandwidthKbps,
        success: bSuccess,
      },
    };

    console.log(JSON.stringify(bandwidthTestsJson, null, 2));

    //submit results
    const postresponse = await fetch("/bwTests", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bandwidthTestsJson),
    });
  }

  useEffect(() => {
    getLoc();
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <p>Bandwidth Test</p>
      </header>
      <body>
        <br />
        <button id="doTest" onClick={doTest}>
          Do Test
        </button>
        <br />
      </body>
    </div>
  );
}

export default App;
